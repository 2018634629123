import React from 'react'

const EmptyList = ({onClick, text}) => {
  return (
    <div className="d-flex align-items-center flex-column">
        <div className="p-2 mt-5">
          <button style={{borderWidth: '4px'}} className='btn btn-outline-warning' onClick={onClick}>+</button>
        </div>
        <div style={{fontWeight: '300', fontSize: '15px'}} className="p-2">{text}</div>
      </div>
  )
}

export default EmptyList