import React, {useState} from "react";
import {useForm} from "react-hook-form";
import {useNavigate} from "react-router-dom";
import {LOGIN_PATH} from "../../utils/navigation_paths";
import logo from "../../assets/adaptive-icon.png";
import users from "../../redux/actions/users";
import {promiseMessage} from "../../utils/helpers/promiseMessage";

const NewPasswordPage = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const {
    register,
    handleSubmit,
    formState: {errors},
    setError: setFormError,
  } = useForm({
    defaultValues: {
      password: "",
      confirmPassword: "",
    },
  });

  const onSubmit = async (data) => {
    setLoading(true);
    setError(null);

    try {
      const userId = localStorage.getItem("resetUserId");
      if (!userId) throw new Error("User ID not found");

      if (data.password !== data.confirmPassword) {
        throw new Error("Passwords do not match");
      }

      const resetPasswordPromise = users.resetPassword(userId, data.password);
      await promiseMessage(
        resetPasswordPromise,
        "Modification de mot de passe en cours",
        "Mot de passe modifié avec succès",
        "Une erreur est survenue"
      );

      navigate(LOGIN_PATH);
    } catch (error) {
      setFormError("confirmPassword", {
        type: "custom",
        message: error.message,
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div
      style={{marginTop: "-50px"}}
      className="container d-flex justify-content-center align-items-center flex-column vh-100">
      <img src={logo} alt="Fewnu logo" className="w-25 mx-auto d-block" />
      <h2 className="titre-ins text-center">
        Définir votre nouveau mot de passe
      </h2>
      <div className="w-100">
        <form onSubmit={handleSubmit(onSubmit)} className="form w-100">
          <input
            type="password"
            {...register("password", {required: true})}
            placeholder="Mot de passe"
            className="form-control mt-3 mb-0 rounded-0 py-3 my-2"
          />
          {errors.password?.type === "required" && (
            <span className="text-danger">Saisissez un mot de passe</span>
          )}

          <input
            type="password"
            {...register("confirmPassword", {required: true})}
            placeholder="Confirmer mot de passe"
            className="form-control mt-3 mb-0 rounded-0 py-3 my-2"
          />
          {errors.confirmPassword?.type === "required" && (
            <span className="text-danger">
              Veuillez confirmer votre mot de passe
            </span>
          )}
          {errors.confirmPassword && (
            <span className="text-danger">
              {errors.confirmPassword.message}
            </span>
          )}
          <button
            disabled={loading}
            type="submit"
            className="btn btn-default inscrire rounded-0 mt-4 py-3 px-4 w-100 fs-5">
            {!loading ? "Réinitialiser" : "Chargement..."}
          </button>
        </form>
      </div>
    </div>
  );
};

export default NewPasswordPage;
