import React, { Suspense } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { Navigate } from 'react-router-dom'
import { ACCUEIL_PATH } from '../utils/navigation_paths'
import ErrorFallback from './ErrorFallback'
import SkeletonSuspense from './SkeletonSuspense'

const AppLazy = ({children}) => {
  return (
    <ErrorBoundary
      fallback={<ErrorFallback />}
      onReset={() => <Navigate to={ACCUEIL_PATH} />}
    >
      <Suspense fallback={<SkeletonSuspense />}>
        {children}
      </Suspense>
    </ErrorBoundary>
  )
}

export default AppLazy