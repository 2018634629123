import { createSlice } from "@reduxjs/toolkit/"

let initialState = {
    currentUser: {},
    listUsers :[]
}
const usersSlice = createSlice({
    name:'users',
    initialState,
    reducers:{
        setCurrentUser:(state,{payload})=>{
            state.currentUser = payload
        },
        setUsersList:(state,{payload})=>{
            state.listUsers = payload
        },
        removeCurrentUser:(state,{payload})=>{
            state.currentUser = {}
        },
        currentUserLogout: (state) => {
            state.currentUser = {}
            localStorage.clear()
            sessionStorage.clear()
            // Storage.clear()
        }
    }
})

export const { setCurrentUser,setUsersList,removeCurrentUser, currentUserLogout} = usersSlice.actions
export default usersSlice.reducer