import { useNavigate } from "react-router-dom"
import { ACCUEIL_PATH } from "../utils/navigation_paths"

const ErrorFallback = ({ error, resetErrorBoundary }) => {
    const navigate = useNavigate()
    return (
        <div className="error d-flex flex-column align-items-center justify-content-center h-75">
            <p style={{fontWeight: '300'}} className="text-dark">Une erreur est survenue</p>
            <pre>{error?.message}</pre>
            <button style={{fontWeight: '300'}} className="btn btn-outline-success" onClick={() => {
                navigate(ACCUEIL_PATH)
                window.location.reload()
            }}>Veuillez réessayer</button>
        </div>
    )
}
export default ErrorFallback