import { ADD_VENTES_API_ROUTE, DELETE_VENTE_API_ROUTE, GET_USER_VENTES_API_ROUTE } from "../../routes/api_routes";
import { apiSlice } from "./api";

export const extendedVenteApi = apiSlice.injectEndpoints({
    // tagTypes: ["Vente", "Solde"],
    endpoints: (build) => ({
        getVentes: build.query({
            query: ({ id, token }) => ({
                url: GET_USER_VENTES_API_ROUTE + id + '/',
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }),
            providesTags: ["Vente"],
        }),
        addVente: build.mutation({
            query(data, token) {
                return {
                    url: ADD_VENTES_API_ROUTE,
                    method: "POST",
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                    body: data,
                };
            },
            invalidatesTags: ["Vente", "Solde"],
        }),
        archiveVente: build.mutation({
            query(produitId, token) {
                return {
                    url: DELETE_VENTE_API_ROUTE + produitId + '/',
                    method: "DELETE",
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                };
            },
            invalidatesTags: ["Vente", "Solde"],
        })
    }),
});

export const {
    useGetVentesQuery, useAddVenteMutation, useArchiveVenteMutation
} = extendedVenteApi;

// export const selected = extendedDepenseApi.endpoints.getDepense.select()