import axios from "axios";
import React, {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {BsArrowLeft} from "react-icons/bs";
import {ACCUEIL_PATH} from "../../utils/navigation_paths";
import {setCurrentTab} from "../../redux/reducers/currentTab";
import {
  ADD_PRODUCT_API_ROUTE,
  EDIT_PRODUCT_API_ROUTE,
} from "../../routes/api_routes";
import {containsOnlyNumbers} from "../../utils/helpers/numberRegex";
import {promiseMessage} from "../../utils/helpers/promiseMessage";

const EditProduct = ({
  productNameToEdit,
  productPrixToEdit,
  productImageToEdit,
  productId,
  setEditProduct,
}) => {
  const navigate = useNavigate();
  const currentUser = useSelector((state) => state.users.currentUser);

  // ! initialize form
  const productData = {
    name: productNameToEdit,
    prix: productPrixToEdit,
  };
  const [loading, setLoading] = useState(false);
  const [products, setProducts] = useState(productData);
  const {name, prix} = products;
  const [file, setFile] = useState(null);
  const [preview, setPreview] = useState(productImageToEdit || "");
  const [fileType, setFileType] = useState(""); // New state for file type
  const dispatch = useDispatch();

  const initialErrors = {
    name: "",
    prix: "",
  };
  const [errors, setErrors] = useState(initialErrors);

  const handleMedia = (e) => {
    const file = e.target.files[0];
    if (file) {
      setFile(file);
      const type = file.type.split("/")[0];
      setFileType(type); // Set file type based on the selected file
      if (type === "image" || type === "video") {
        setPreview(URL.createObjectURL(file));
      } else {
        setPreview("");
      }
    } else {
      setFile(null);
      setPreview(productImageToEdit || "");
    }
  };

  const handleChange = (e) => {
    setErrors(initialErrors);
    setProducts({...products, [e.target.name]: e.target.value});
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!name) {
      return setErrors((errors) => ({
        ...errors,
        name: "Veuillez mettre le nom du produit",
      }));
    }
    if (!prix) {
      return setErrors((errors) => ({
        ...errors,
        prix: "Veuillez mettre le prix",
      }));
    }
    if (Number(prix) <= 0) {
      return setErrors((errors) => ({
        ...errors,
        prix: "Le prix ne peux pas être nul",
      }));
    }
    if (!containsOnlyNumbers(prix)) {
      return setErrors((errors) => ({
        ...errors,
        prix: "Le prix de doit pas contenir des lettres",
      }));
    }

    setLoading(true);
    const formData = new FormData();
    formData.append("name", name);
    formData.append("prix", prix);
    formData.append("createdBy", currentUser.id);
    if (file) {
      formData.append(fileType === "image" ? "image" : "video", file); // Append file to the correct column
    }

    const myPromise = axios
      .put(EDIT_PRODUCT_API_ROUTE + productId + "/", formData)
      .then(() => {
        setLoading(false);
        dispatch(setCurrentTab(2));
        navigate(ACCUEIL_PATH);
      })
      .catch(() => {
        setLoading(false);
      });

    promiseMessage(
      myPromise,
      "Modification produit en cours",
      "Produit Modifié",
      "Une erreur est survenue"
    );
  };

  return (
    <div className="mt-0">
      <div className="d-flex align-items-center flex-column px-2">
        <div className="text-left">Modifier l'article</div>
        <form className="w-100" onSubmit={handleSubmit}>
          <div className="card mt-5">
            <div className="card-body">
              <input
                type="text"
                name="name"
                value={name}
                onChange={handleChange}
                className="form-control py-2"
                placeholder="Nom article"
              />
              {errors.name && (
                <p
                  style={{fontWeight: "300", fontSize: "0.9rem"}}
                  className="mt-1 text-danger">
                  {errors.name}
                </p>
              )}

              <div className="mt-3">
                <input
                  type="number"
                  name="prix"
                  value={prix}
                  onChange={handleChange}
                  className="form-control py-2"
                  placeholder="Prix"
                />
              </div>
              {errors.prix && (
                <p
                  style={{fontWeight: "300", fontSize: "0.9rem"}}
                  className="mt-1 text-danger">
                  {errors.prix}
                </p>
              )}

              <div className="mt-3">
                <input
                  type="file"
                  name="image"
                  onChange={handleMedia}
                  accept="image/*,video/*"
                  className="form-control py-2"
                />
                {preview && (
                  <div className="mt-3">
                    {file && file.type.startsWith("image") ? (
                      <img
                        src={preview}
                        alt="Preview"
                        style={{maxWidth: "100%", height: "auto"}}
                      />
                    ) : file && file.type.startsWith("video") ? (
                      <video
                        controls
                        src={preview}
                        style={{maxWidth: "100%", height: "auto"}}
                      />
                    ) : null}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="d-grid gap-2 mt-3">
            <button
              disabled={loading}
              className="btn-edit text-white"
              type="submit">
              {loading ? "..." : "Modifier"}
            </button>
            <button
              onClick={() => setEditProduct(false)}
              className="btn btn-delete btn-danger"
              type="button">
              Annuler
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EditProduct;
