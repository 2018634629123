import React from 'react'
import { useNavigate } from 'react-router-dom'

const Page404 = () => {
  const navigate = useNavigate()
  return (
    <div className='d-flex flex-column align-items-center justify-content-center h-75'>
      <p>Page introuvable</p>
      <h1 className='m-0'>404</h1>
      <button onClick={() => navigate(-1)} className='btn btn-outline-secondary mt-2' type="button">Retour</button>
    </div>
  )
}

export default Page404