import React, {useState} from "react";
import {useForm} from "react-hook-form";
import {Link, useNavigate} from "react-router-dom";
import {
  ACCUEIL_PATH,
  FORGOT_PASSWORD_PATH,
  REGISTER_PATH,
} from "../../utils/navigation_paths";
import logo from "../../assets/adaptive-icon.png";
import users from "../../redux/actions/users";
import {useDispatch} from "react-redux";
import {setCurrentUser} from "../../redux/reducers/users";

const LoginPage = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState();
  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    formState: {errors},
    setError,
  } = useForm({
    defaultValues: {
      phone: "",
      password: "",
    },
  });

  const onSubmit = async (data) => {
    setLoading(true);

    try {
      const result = await users.loginUser(data);
      dispatch(setCurrentUser(result));
      navigate(ACCUEIL_PATH);
    } catch (error) {
      if (error?.response?.data?.message) {
        setError("phone", {
          type: "custom",
          message: error?.response?.data?.message,
        });
      } else {
        setError("phone", {
          type: "custom",
          message: "Une erreur de connexion s'est produite",
        });
      }
      console.log(error);
    }
    setLoading(false);
  };

  return (
    <div
      style={{marginTop: "-50px"}}
      className="container d-flex justify-content-center align-items-center flex-column vh-100">
      <img src={logo} alt="Fewnu logo" className="w-25 mx-auto d-block" />
      <h2 className="titre-ins text-center">Connectez-vous</h2>
      <div className="w-100">
        <form onSubmit={handleSubmit(onSubmit)} className="form w-100">
          <input
            {...register("phone", {required: true})}
            placeholder="Téléphone"
            className="form-control rounded-0 mt-4 py-3"
          />
          {errors.phone && (
            <span className="text-danger">{errors.phone.message}</span>
          )}
          <input
            type="password"
            {...register("password", {required: true})}
            placeholder="Mot de passe"
            className="form-control rounded-0 mt-4 py-3"
          />
          {errors.phone && (
            <span className="text-danger">{errors.phone.message}</span>
          )}
          <p className="mt-3 text-start">
            <Link to={FORGOT_PASSWORD_PATH} className="link">
              Mot de passe oublié!
            </Link>
          </p>
          <button
            disabled={loading}
            type="submit"
            className="btn btn-default inscrire rounded-0 mt-3 py-3 px-4 w-100 fs-5">
            {!loading ? "Se connecter" : "Connexion..."}
          </button>
        </form>
      </div>
      <p className="mt-4 text-center">
        Je n'ai pas encore de compte !{" "}
        <Link to={REGISTER_PATH} className="link">
          m'inscrire
        </Link>
      </p>
    </div>
  );
};

export default LoginPage;
