import { apiSlice } from "./api";

export const extendedDepenseApi = apiSlice.injectEndpoints({
  // tagTypes: ["Depense"],
  endpoints: (build) => ({
    getDepenses: build.query({
      query: (id) => `depenses/user/${id}/`,
      providesTags: ["Depense"],
    }),
    getDepensesType: build.query({
      query: () => `type-depenses/`,
      providesTags: ["DepenseTypes"],
    }),
    addDepense: build.mutation({
      query(body, token) {
        return {
          url: `depenses/`,
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
          },
          body,
        };
      },
      invalidatesTags: ["Depense", "Solde"],
    }),
    getDepense: build.query({
      query: (id) => `depenses/${id}/`,
      providesTags: ["Depense"],
    }),
    updateDepense: build.mutation({
      query(data) {
        const { id, ...body } = data;
        return {
          url: `posts/${id}`,
          method: "PUT",
          body,
        };
      },
      invalidatesTags: ["Depense"],
    }),
    deleteDepense: build.mutation({
      query({ id, token }) {
        return {
          url: `depenses/${id}/`,
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };
      },
      invalidatesTags: ["Depense", "Solde"],
    }),
  }),
});

export const {
  useGetDepensesQuery,
  useGetDepensesTypeQuery,
  useAddDepenseMutation,
  useGetDepenseQuery,
  useUpdateDepenseMutation,
  useDeleteDepenseMutation,
} = extendedDepenseApi;

// export const selected = extendedDepenseApi.endpoints.getDepense.select()