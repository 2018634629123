import React, {memo} from "react";
import {DEFAULT_API_IMAGE, DEFAULT_API_VIDEO} from "../../../routes/api_routes";
import {converTime} from "../../../utils/agregation";
import {AiOutlineDelete} from "react-icons/ai";
import {Link} from "react-router-dom";

function Card({
  image,
  name,
  quantity,
  prix,
  date,
  id,
  onClickRedirectTo,
  onClick,
  onClickAdd,
  showDelete,
  cardType,
  intitule,
  video,
}) {
  const isVideo = video?.match(/\.(mp4|mov|avi|mkv|flv)$/i);
  console.log(DEFAULT_API_VIDEO + video);
  return cardType === "nonCliquable" ? (
    <div
      style={{cursor: "pointer"}}
      className="contaierCard"
      onClick={onClickAdd ?? null}>
      <div className="imageContainer">
        {isVideo ? (
          <div
            className="video-thumbnail"
            style={{height: "55px", width: "100%"}}>
            {/* Display a default video thumbnail or a play button */}
            <video
              src={DEFAULT_API_VIDEO + video}
              alt="Video Thumbnail"
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
              }}
            />
          </div>
        ) : (
          <img
            src={image ? DEFAULT_API_IMAGE + image : null}
            alt={name}
            className="image"
          />
        )}
      </div>
      <div
        style={{minHeight: "70px"}}
        className="card-containt d-flex justify-content-between">
        <div className="card-containt-left">
          <p style={{fontSize: "0.9rem"}} className="">
            {intitule ? intitule : name}
          </p>
          {quantity && <p className="card-text">Quantité: {quantity}</p>}
          {prix && <p className="card-text prix">Prix: {prix}f cfa</p>}
        </div>
        <div className="card-containt-right d-flex flex-column justify-content-between">
          {date && <time style={{fontSize: "0.7rem"}}>{converTime(date)}</time>}
          {showDelete && (
            <AiOutlineDelete
              className="mt-3"
              onClick={onClick ?? null}
              color="red"
              size={20}
            />
          )}
        </div>
      </div>
    </div>
  ) : (
    <div
      style={{cursor: "pointer"}}
      className="contaierCard"
      onClick={onClickAdd ?? null}>
      <Link
        to={`${onClickRedirectTo}${id}`}
        style={{
          textDecoration: "none",
          cursor: "pointer",
          color: "rgba(0,0,0,0.7)",
        }}>
        <div className="imageContainer">
          {isVideo ? (
            <div
              className="video-thumbnail"
              style={{height: "55px", width: "100%"}}>
              <video
                src={DEFAULT_API_VIDEO + video}
                alt="Video Thumbnail"
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                }}
              />
            </div>
          ) : (
            <img
              src={image ? DEFAULT_API_IMAGE + image : null}
              alt={name}
              className="image"
            />
          )}
        </div>
      </Link>
      <div className="card-containt d-flex justify-content-between">
        <Link
          to={`${onClickRedirectTo}${id}`}
          style={{
            textDecoration: "none",
            cursor: "pointer",
            color: "rgba(0,0,0,0.7)",
            width: "90%",
          }}>
          <div className="card-containt-left">
            <p style={{fontSize: "0.9rem"}} className="">
              {intitule ? intitule : name}
            </p>
            {quantity && <p className="card-text">Quantité: {quantity}</p>}
            {prix && <p className="card-text prix">Prix: {prix}f cfa</p>}
          </div>
        </Link>
        <div className="card-containt-right d-flex flex-column justify-content-between">
          {date && <time style={{fontSize: "0.7rem"}}>{converTime(date)}</time>}
          {showDelete && (
            <AiOutlineDelete
              className="mt-3"
              onClick={onClick ?? null}
              color="red"
              size={20}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default memo(Card);
