import React from "react";
import { useParams } from "react-router-dom";
import DetailsLayout from "../../layout/DetailsLayout";
import { useGetDepenseQuery } from "../../redux/features/depenseApi";
import { DEFAULT_API_IMAGE } from "../../routes/api_routes";
import { convertDateToString } from "../../utils/convertDate";

function DetailsDepense() {
  const { id } = useParams();

  const { data: depense, isLoading } = useGetDepenseQuery(id);

  //   console.log(id, depense);
  return (
    <DetailsLayout id={id} title={"Depense"}>
      {isLoading ? (
        <div>Loading...</div>
      ) : (
        <>
          <img
            src={DEFAULT_API_IMAGE + depense?.typeDepense?.image}
            style={{
              width: '100',
              minHeight: 100,
              borderRadius: 10,
              marginBottom: 10,
            }}
            alt=""
          />
          <div style={{}}>
            <span style={{ marginBottom: 30 }}>
              <span style={{ color: "rgba(0,0,0,0.5)", fontSize: 15 }}>
                Montant:
              </span>
              <p
                style={{
                  color: "rgba(0,0,0,0.8)",
                  fontSize: 14,
                  fontWeight: 400,
                }}
              >
                {depense?.montant} Fcfa{" "}
              </p>
            </span>
            <span style={{ marginBottom: 30 }}>
              <span style={{ color: "rgba(0,0,0,0.5)", fontSize: 15 }}>
                Type de depense:
              </span>
              <p
                style={{
                  color: "rgba(0,0,0,0.8)",
                  fontSize: 14,
                  fontWeight: 400,
                }}
              >
                {" "}
                {depense?.typeDepense?.name}{" "}
              </p>
            </span>
            <span style={{ marginBottom: 30 }}>
              <span style={{ color: "rgba(0,0,0,0.5)", fontSize: 15 }}>
                Date de transaction:
              </span>
              <p
                style={{
                  color: "rgba(0,0,0,0.8)",
                  fontSize: 14,
                  fontWeight: 400,
                }}
              >
                {convertDateToString(depense?.date)}
              </p>
            </span>
          </div>
        </>
      )}
    </DetailsLayout>
  );
}

export default DetailsDepense;
