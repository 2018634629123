export const DEFAULT_PATH = "*";

/* ===================== AUTH PATHS =================== */
export const LOGIN_PATH = "/login";
export const REGISTER_PATH = "/register";
export const FORGOT_PASSWORD_PATH = "/forgot-password";
export const NEW_PASSWORD_PATH = "/new-password";

/* ===================== HOME PATH =================== */
export const ACCUEIL_PATH = "/accueil";
export const VENTES_PATH = "";
export const DEPENSES_PATH = "/depenses";
export const PRODUITS_PATH = "/produits";

/* ===================== PRODUCTS PATHS =================== */
export const PRODUCT_PATH = "/products";
export const ADD_PRODUCT_PATH = "/add-product";

/* ===================== GALERIE PATHS =================== */
export const GALERIE_PATH = "/galerie";

/* ===================== DETAILS ===================== */
export const DETAILS_VENTE_PATH = "/details/vente/";
export const DETAILS_PRODUCT_PATH = "/details/produit/";
export const DETAILS_DEPENSE_PATH = "/details/depense/";

/* ===================== SETTINGS ===================== */
export const SETTINGS_PATH = "/settings/";

/* ===================== SETTINGS ===================== */
export const HISTORIQUE_PATH = "/historique/";
