import React from 'react'
import image from '../assets/adaptive-icon.png'
import Spinner from './Spinner'

const SkeletonSuspense = () => {
    return (
        <div className='w-50 mx-auto h-100 d-flex flex-column align-items-center justify-content-center'>
            <img className='img-fluid image-splash' src={image} alt="Fewnu" />
            {/* <p>Loading...</p> */}
            <Spinner />
        </div>
    )
}

export default SkeletonSuspense