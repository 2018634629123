import toast from "react-hot-toast"

export default function successMessage(message) {
  toast.success(message,{
    duration: 4000,
    style:{
      textAlign: 'center',
      fontSize: '0.8rem',
      position: 'relative',
      bottom: '2rem'
    }
  })
}