/* eslint-disable no-extend-native */
/**
 * +--------------------------------------------------------------------------+
 * | Converte a Date to yyy-mm-dd                                             |
 * +--------------------------------------------------------------------------+
 */
String.prototype.convertDate = function () {
  const newDate = new Date(this);

  const converNewdate = `${newDate.getFullYear()}-${(
    newDate.getMonth() + 1
  ).addZero()}-${newDate.getDate().addZero()}`;

  return converNewdate;
};

/**
 * +--------------------------------------------------------------------------+
 * | Converte a number to string                                              |
 * +--------------------------------------------------------------------------+
 */
export const convertSold = function (data) {
  return data.toString().split("").addSoldSpace().join(" ");
};

/**
 * +--------------------------------------------------------------------------+
 * | Add space to sold after each 3 number from right                        |
 * +--------------------------------------------------------------------------+
 */
Array.prototype.addSoldSpace = function () {
  const reverse = this.reverse();
  let space = [];
  let copy = [];
  for (let i = 0; i < this.length; i += 3) {
    copy = [...reverse];
    space.push(copy.splice(i, 3).reverse().join(""));
  }

  return space.reverse();
};

/**
 * +--------------------------------------------------------------------------+
 * | Get time with a specifique date                                          |
 * +--------------------------------------------------------------------------+
 */
export const converTime = function (data) {
  const newTime = new Date(data);

  return `${newTime.getHours().addZero()}:${newTime.getMinutes().addZero()}`;
};

/**
 * +--------------------------------------------------------------------------+
 * | Compare date                                                             |
 * +--------------------------------------------------------------------------+
 */
export const compareDate = function (data) {
  const newDate = new Date(data);
  const todayDate = new Date();
  const yesterdayDate = new Date();

  const months = [
    "Janvier",
    "Fevrier",
    "Mars",
    "Avril",
    "Mai",
    "Juin",
    "Juillet",
    "Aout",
    "Septembre",
    "Octobre",
    "Novembre",
    "Decembre",
  ];

  const converNewdate = `${newDate.getFullYear()}-${(
    newDate.getMonth() + 1
  ).addZero()}-${newDate.getDate().addZero()}`;

  const converTodayDate = `${todayDate.getFullYear()}-${(
    todayDate.getMonth() + 1
  ).addZero()}-${todayDate.getDate().addZero()}`;

  const converYesterdayDate = `${yesterdayDate.getFullYear()}-${(
    yesterdayDate.getMonth() + 1
  ).addZero()}-${(yesterdayDate.getDate() - 1).addZero()}`;

  if (converTodayDate === converNewdate) {
    return "Ajourd'hui";
  } else if (converYesterdayDate === converNewdate) {
    return "Hier";
  } else {
    return `${newDate.getDate().addZero()} ${months[newDate.getMonth()]
      } ${newDate.getFullYear()}`;
  }
};

/**
 * +--------------------------------------------------------------------------+
 * | Get date                                                                 |
 * +--------------------------------------------------------------------------+
 */
String.prototype.getDateDate = function () {
  const date = new Date(this);
  return date.getDate() + date.getFullYear();
};

/**
 * +--------------------------------------------------------------------------+
 * | Sort data                                                                |
 * +--------------------------------------------------------------------------+
 */
Array.prototype.SorteData = function () {
  return this.sort((a, b) => b.getDateDate() - a.getDateDate());
};

/**
 * +--------------------------------------------------------------------------+
 * | Add zero from 0 to 9                                                     |
 * +--------------------------------------------------------------------------+
 */
Number.prototype.addZero = function () {
  if (this < 10) {
    return "0" + this;
  } else {
    return this;
  }
};

/**
 * +--------------------------------------------------------------------------+
 * | Agregate data                                                            |
 * +--------------------------------------------------------------------------+
 */
export const agregate = function (arrayData) {
  let Dates = [];
  let arrayAgrageted = [];
  // ! on recupere les dates qui ne sont pas dans Dates[]
  for (let i = 0; i < arrayData.length; i++) {
    if (
      Dates.filter((el) => el === arrayData[i]?.date?.convertDate()).length ===
      0
    ) {
      Dates.push(arrayData[i]?.date?.convertDate());
    }
  }
  const newDates = Dates.SorteData();
  // console.log({ newDates });

  for (let i = 0; i < newDates.length; i++) {
    const dataDate = arrayData.map(
      (el) => el?.date?.convertDate() === newDates[i] && el
    );
    const goodData = dataDate.filter((el) => el !== false)
    goodData.sort((a, b) => {
      return (new Date(a.date) > new Date(b.date) ? 1 : new Date(a.date) < new Date(b.date) ? -1 : 0)
    })
    // console.log({goodData});
    const data = {
      date: newDates[i],
      data: goodData,
    };
    arrayAgrageted.push({ ...data });
  }

  // console.log(arrayAgrageted);

  return arrayAgrageted;
};

/**
 * +--------------------------------------------------------------------------+
 * | Add space to phone number                                                |
 * +--------------------------------------------------------------------------+
 */

export const convertPhone = function (data) {
  if (data.length === 9) {
    return `${data.slice(0, 2)} ${data.slice(2, 5)} ${data.slice(
      5,
      7
    )} ${data.slice(7, 9)}`;
  }

  return data;
};
