import React, {useState} from "react";
import {useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import DetailsLayout from "../../layout/DetailsLayout";
import {DEFAULT_API_IMAGE, DEFAULT_API_VIDEO} from "../../routes/api_routes";
import EditProduct from "./EditProduct";

function DetailsProduit() {
  const {id} = useParams();
  const [isEditProduct, setEditProduct] = useState(false);
  const products = useSelector((state) => state.products.listProducts);
  const product = products?.find((p) => p.id === Number(id));

  const isVideo = product?.video?.match(/\.(mp4|mov|avi|mkv|flv)$/i);
  return (
    <>
      <DetailsLayout id={id} title={"Produit"}>
        {!product ? (
          <div>Produit introuvable</div>
        ) : (
          <>
            {!isEditProduct ? (
              <>
                <div
                  style={{
                    width: "100%",
                    height: "fit-content",
                    borderRadius: 10,
                    marginBottom: 20,
                    overflow: "hidden",
                    border: "1px solid rgba(0,0,0,0.1)",
                  }}>
                  {isVideo ? (
                    <div className="video-thumbnail">
                      <video
                        controls
                        src={DEFAULT_API_VIDEO + product?.video}
                        style={{maxWidth: "100%", height: "auto"}}
                      />
                    </div>
                  ) : (
                    <img
                      src={DEFAULT_API_IMAGE + product?.image}
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "contain",
                      }}
                      alt={product?.name}
                    />
                  )}
                </div>
                <div style={{}}>
                  <span style={{fontWeight: "400"}}>
                    Libele: {product?.name}
                  </span>
                  <p style={{fontWeight: "300"}}>Prix: {product?.prix} Fcfa</p>
                </div>
                <button
                  className="btn-edit w-100"
                  type="button"
                  onClick={() => setEditProduct(true)}>
                  Modifier
                </button>
              </>
            ) : (
              <EditProduct
                productId={product?.id}
                productNameToEdit={product?.name}
                productPrixToEdit={product?.prix}
                productImageToEdit={product?.image}
                setEditProduct={setEditProduct}
              />
            )}
          </>
        )}
      </DetailsLayout>
    </>
  );
}

export default DetailsProduit;
