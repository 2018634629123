import { createSlice } from "@reduxjs/toolkit/"

let initialState = {
    currentDepense: { typeDepenseId: null, montant: null, createdBy: null, archived: false, date: '' },
    listDepense: [],
    listAllDepense: [],
    depenseIdToDelete: null,
    openDepenseModal: false,
    selectedDepenseItem: {},
    isAutreDepense: false 
}
const depensesSlice = createSlice({
    name: 'depenses',
    initialState,
    reducers: {
        setCurrentDepense: (state, { payload }) => {
            state.currentDepense = payload
        },
        setDepensesList: (state, { payload }) => {
            state.listDepense = payload
            state.listAllDepense = payload
        },
        resetCurrentDepense: (state, { payload }) => {
            state.currentDepense = {}
        },
        filterDepensesBySearch: (state, { payload }) => {
            state.listDepense = state.listAllDepense.filter(item => {
                let fields = payload.fields.map((field) => item[field])

                let data = fields.join(' ').toLowerCase()
                return data.includes(payload.value.toLowerCase())
            })
        },
        setDepenseIdToDelete: (state, { payload }) => {
            state.depenseIdToDelete = payload
        },
        setOpenDeleteDepenseModal: (state, { payload }) => {
            state.openDepenseModal = payload
        },
        setSelectedDepenseItem: (state, { payload }) => {
            state.selectedDepenseItem = payload
        },
        initializeDepense: (state) => {

        },
        setIsAutreDepense: (state, { payload }) => {
            state.isAutreDepense = payload
        }


    }
})

export const { setCurrentDepense, setDepensesList, resetCurrentDepense, filterDepensesBySearch, setDepenseIdToDelete, setOpenDeleteDepenseModal, setSelectedDepenseItem, setIsAutreDepense } = depensesSlice.actions
export default depensesSlice.reducer