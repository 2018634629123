import React, {useState} from "react";
import {useForm} from "react-hook-form";
import {Link, useNavigate} from "react-router-dom";
import logo from "../../assets/adaptive-icon.png";
import users from "../../redux/actions/users";
import axios from "axios";
import {GET_USER_API_ROUTE} from "../../routes/api_routes";

const ForgotPasswordPage = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState();
  const {
    register,
    handleSubmit,
    formState: {errors},
    setError,
  } = useForm({
    defaultValues: {phone: ""},
  });

  const findUserByPhone = async (phone) => {
    try {
      // Fetch all users
      const response = await axios.get(GET_USER_API_ROUTE);
      console.log(response);

      if (response.status === 200) {
        // Filter the users to find the one with the matching phone number
        const user = response.data.data.find((user) => user.phone === phone);
        console.log(user);

        if (user) {
          return user;
        } else {
          throw new Error("Utilisateur non trouvé");
        }
      } else {
        throw new Error("Erreur lors de la récupération des utilisateurs");
      }
    } catch (error) {
      throw new Error(
        error.response?.data?.message || "Une erreur s'est produite"
      );
    }
  };

  const onSubmit = async (data) => {
    setLoading(true);
    setError(null);

    try {
      const user = await findUserByPhone(data.phone);
      localStorage.setItem("resetUserId", user.id);
      navigate("/new-password"); // Redirect to the new password page
    } catch (error) {
      setError("phone", {
        type: "custom",
        message: error.message,
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div
      style={{marginTop: "-50px"}}
      className="container d-flex justify-content-center align-items-center flex-column vh-100">
      <img src={logo} alt="Fewnu logo" className="w-25 mx-auto d-block" />
      <h2 className="titre-ins text-center">
        Réinitialiser votre mot de passe
      </h2>
      <div className="w-100">
        <form onSubmit={handleSubmit(onSubmit)} className="form w-100">
          <input
            {...register("phone", {required: true})}
            placeholder="Téléphone"
            className="form-control rounded-0 mt-4 py-3"
          />
          {errors.phone && (
            <span className="text-danger">{errors.phone.message}</span>
          )}
          <button
            disabled={loading}
            type="submit"
            className="btn btn-default inscrire rounded-0 mt-4 py-3 px-4 w-100 fs-5">
            {!loading ? "Réinitialiser" : "Connexion..."}
          </button>
          <p className="mt-3">
            <Link to="/" className="link">
              Se connecter
            </Link>
          </p>
        </form>
      </div>
    </div>
  );
};

export default ForgotPasswordPage;
