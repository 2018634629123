import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import EmptyList from "../../components/EmptyList";
import produits from "../../redux/actions/produits";
import {
  setOpenDelete,
  setProductIdToDelete,
  setSelectedProduct,
} from "../../redux/reducers/products";
import { DETAILS_PRODUCT_PATH } from "../../utils/navigation_paths";
import Card from "../depence/components/Card";

export const Products = () => {
  const navigate = useNavigate();
  const products = useSelector((state) => state.products.listProducts);
  const currentUser = useSelector((state) => state.users.currentUser);
  const dispatch = useDispatch();

  const goToAddProductPage = () => {
    navigate("/add-product");
  };

  useEffect(() => {
    const getProducts = async () => {
      let token = currentUser.token.token;
      produits.getUserProductsList(dispatch, token, currentUser.id);
    };
    getProducts();
  }, [currentUser.id]);

  return (
    <>
      {products.length ? (
        products.map((product) => (
          <Card
            onClickRedirectTo={DETAILS_PRODUCT_PATH}
            showDelete={true}
            onClick={() => {
              dispatch(setProductIdToDelete(product.id));
              dispatch(setSelectedProduct(product));
              dispatch(setOpenDelete(true));
            }}
            key={product.id}
            {...product}
          />
        ))
      ) : (
        <EmptyList
          onClick={goToAddProductPage}
          text="Veuillez ajouter un produit"
        />
      )}
    </>
  );
};
