import { combineReducers, configureStore } from "@reduxjs/toolkit";
import navigation from "../reducers/navigation";
import users from "../reducers/users";
import products from "../reducers/products";
import ventes from "../reducers/ventes";
import currentTab from '../reducers/currentTab'
import storage from 'redux-persist/lib/storage';
import solde from '../reducers/solde'
// import storageSession from 'reduxjs-toolkit-persist/lib/storage/session'
import {
    persistReducer, persistStore, FLUSH,
    REHYDRATE,
    PAUSE,
    PERSIST,
    PURGE,
    REGISTER,
} from "redux-persist";
import thunk from "redux-thunk";
import { apiSlice } from "../features/api";
import depenses from "../reducers/depenses";
import { extendedVenteApi } from "../features/venteApi";
import { extendedDepenseApi } from "../features/depenseApi";
import { extendedSoldeApi } from "../features/soldeApi";

const persistConfig = {
    key: "f",
    storage,
};

const rootReducer = combineReducers({
    users,
    navigation,
    products,
    ventes,
    depenses,
    [extendedSoldeApi.reducerPath]: extendedSoldeApi.reducer,
    [extendedDepenseApi.reducerPath]: extendedDepenseApi.reducer,
    [extendedVenteApi.reducerPath]: extendedVenteApi.reducer,
    [apiSlice.reducerPath]: apiSlice.reducer,
    solde,
    currentTab
})
// const userPersistConfig = {
//     key: 'u',
//     storage: storageSession,
// }

const persistedReducer = persistReducer(persistConfig, rootReducer)

export const store = configureStore({
    reducer: persistedReducer,
    devTools: true,
    //   middleware: [
    //     thunk,
    //     (getDefaultMiddleware) =>
    //       getDefaultMiddleware().concat([depenseApi.middleware]),
    //   ],
    // middleware: (getDefaultMiddleware) =>
    //     getDefaultMiddleware().concat([apiSlice.middleware]),
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            // Redux persist
            serializableCheck: {
                ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
            },
        }).concat(
            thunk,
            extendedSoldeApi.middleware,
            extendedDepenseApi.middleware,
            extendedVenteApi.middleware,
            apiSlice.middleware,
        ),
});

export const persistor = persistStore(store);
