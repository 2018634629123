import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';
import logo from '../../assets/adaptive-icon.png'
import { LOGIN_PATH } from '../../utils/navigation_paths';
import users from '../../redux/actions/users'
import successMessage from '../../utils/helpers/sucessMessage';

const RegisterPage = () => {

  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)

  const { register, handleSubmit, formState: { errors }, setError } = useForm({
    defaultValues: {
      fullName: '',
      phone: '',
      password: '',
      confirmPassword: ''
    }
  });
  const onSubmit = async (data) => {
    if(data.password !== data.confirmPassword){
      return setError("confirmPassword", {type: "custom",message: "Les deux mots de passe doivent correspondre"})
    }
    setError("confirmPassword", {message: ""})
    setLoading(true)
    const userData = { fullName: data.fullName, phone: data.phone, password: data.password }
    try {
      await users.registerUser(userData)
      successMessage("Inscription réuissie, veuillez vous connecter")
      navigate(LOGIN_PATH)
    } catch (error) {
      if(error?.response?.data?.phone){
        setError("phone", {type: "custom",message: "Cet utilisateur existe déjà"})
      }
    }
    setLoading(false)
  }
  return (
    <div className='container d-flex justify-content-center align-items-center flex-column vh-100 '>
      <img src={logo} alt="Fewnu logo" className='w-25 mx-auto d-block' />
      <h2 className='titre-ins'>Inscrivez-vous</h2>
      <div className='w-100'>
        <form onSubmit={handleSubmit(onSubmit)} className='form w-100'>

          <input type='text' {...register("fullName", { required: true })} placeholder="Prénom et nom" className='form-control mt-3 mb-0 rounded-0 py-3 my-2' />
          {errors.fullName?.type === "required" && <span className='text-danger'>Entrez un nom d'utilisateur</span>}

          <input type='tel' {...register("phone", { required: true })} placeholder="Téléphone" className='form-control mt-3 mb-0 rounded-0 py-3 my-2' />
          {errors.phone?.type === "required" && <span className='text-danger'>Entrez un numéro de téléphone</span>}
          {errors.phone && <span className='text-danger'>{errors.phone.message}</span>}

          <input type='password' {...register("password", { required: true })} placeholder="Mot de passe" className='form-control mt-3 mb-0 rounded-0 py-3 my-2' />
          {errors.password?.type === "required" && <span className='text-danger'>Saisissez un mot de passe</span>}

          <input type='password' {...register("confirmPassword", { required: true })} placeholder="Confirmer mot de passe" className='form-control mt-3 mb-0 rounded-0 py-3 my-2' />
          {errors.confirmPassword?.type === "required" && <span className='text-danger'>Veuillez confirmer votre mot de passe</span>}
          {errors.confirmPassword && <span className='text-danger'>{errors.confirmPassword.message}</span>}

          <button disabled={loading} type='submit' className='btn btn-default inscrire rounded-0 mt-4 py-3 px-4 w-100 fs-5'>{!loading ? "S'inscrire" : "Inscription..."}</button>
        </form>
      </div>
      <p className='mt-3'>J'ai déja un compte ! <Link to='/' className='link'>me connecter</Link></p>
    </div>
  );
};

export default RegisterPage;