import { createSlice } from "@reduxjs/toolkit/"

let initialState = {
    currentVente: { productId: null, quantity: '', archived: false, createdBy: null, date: '' },
    listVentes: [],
    listAllVentes: [],
    selectedVenteId: null,
    openVenteDelete: false,
    selectedVenteItem: {}
}
const ventesSlice = createSlice({
    name: 'ventes',
    initialState,
    reducers: {
        initializeCurrentVente: (state) => {
            state.currentVente = { productId: null, quantity: '', archived: false, createdBy: null, date: '' }
            state.listVentes = []
            // state.listAllVentes = []
        },
        addNewVente: (state, { payload }) => {
            state.listVentes = [...state.listAllVentes, payload]
            state.listAllVentes = [...state.listAllVentes, payload]
        },
        setCurrentVente: (state, { payload }) => {
            state.currentVente = { ...state.currentVente, ...payload }
        },
        setListVentes: (state, { payload }) => {
            state.listVentes = payload
            state.listAllVentes = payload
        },
        setListVentesNoApi: (state, { payload }) => {
            state.listVentes = [...state.listVentes, payload]
        },
        resetCurrentVente: (state, { payload }) => {
            state.currentVente = {}
        },
        filterVentesBySearch: (state, { payload }) => {
            state.listVentes = state.listAllVentes.filter(item => {
                let fields = payload.fields.map((field) => item[field])

                let data = fields.join(' ').toLowerCase()
                return data.includes(payload.value.toLowerCase())
            })
        },
        setSelectedProductId:(state, {payload}) => {
            state.selectedVenteId = payload
        },
        setOpenDeleteProduct: (state, {payload}) => {
            state.openVenteDelete = payload
        },
        setSelectedVenteItem:(state, {payload}) => {
            state.selectedVenteItem = payload
        }
    }
})

export const { addNewVente, setCurrentVente, setListVentesNoApi, setListVentes, resetCurrentVente, filterVentesBySearch, initializeCurrentVente, setSelectedProductId, setOpenDeleteProduct, setSelectedVenteItem } = ventesSlice.actions
export default ventesSlice.reducer