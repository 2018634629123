import { GET_USER_SOLDE_API_ROUTE } from "../../routes/api_routes";
import { apiSlice } from "./api";

export const extendedSoldeApi = apiSlice.injectEndpoints({
    // tagTypes: ["Depense", "Vente"],
    endpoints: (builder) => ({
        getSolde: builder.query({
            query: (id) => GET_USER_SOLDE_API_ROUTE + id + '/',
            providesTags: ["Solde"]
        }),
        addSolde: builder.mutation({
            query({solde, userId}) {
                return {
                    url: 'soldes/' + userId + '/',
                    method: "PUT",
                    body: {solde},
                }
            },
            invalidatesTags: ["Solde"]
        })
    })
})

export const { useGetSoldeQuery, useAddSoldeMutation } = extendedSoldeApi