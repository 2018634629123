import { useEffect, useState } from 'react';
import { createBrowserRouter, createRoutesFromElements, Route, RouterProvider } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap';
import './app.css';
import navigation_routes from './routes/navigation_routes';
import 'normalize.css';
import './styles.css';
import { Toaster } from 'react-hot-toast';
import Modal from "react-modal";

function App() {
  const [deferredPrompt, setDeferredPrompt] = useState(null);
  const [opened, setOpened] = useState(false);

  const handleInstall = () => {
    setOpened(false);
    if (deferredPrompt) {
      deferredPrompt.prompt();
      deferredPrompt.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === "accepted") {
          console.log("User accepted the A2HS prompt");
        } else {
          console.log("User dismissed the A2HS prompt");
        }
        setDeferredPrompt(null);
      });
    }
  };

  useEffect(() => {
    const handleBeforeInstallPrompt = (e) => {
      e.preventDefault();
      setDeferredPrompt(e);
      setOpened(true);
    };

    window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);

    return () => {
      window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
    };
  }, []);

  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route>
        {navigation_routes.map((item, index) => (
          <Route key={index} path={item.path} element={item.element} />
        ))}
      </Route>
    )
  );

  return (
    <div className='app'>
      <ToastContainer />
      <RouterProvider router={router} />
      <Toaster position="bottom-center" reverseOrder={false} />
      <Modal
        isOpen={opened}
        onRequestClose={() => setOpened(false)}
        contentLabel="Install App Modal"
        className="alert-modal"
      >
        <div>
          <p>Voulez-vous installer l'application ?</p>
          <button onClick={handleInstall}>Installer</button>
          <button
          className='cancel-btn ms-2'
          onClick={() => setOpened(false)}>Annuler</button>
        </div>
      </Modal>
    </div>
  );
}

export default App;
