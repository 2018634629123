import {store} from "../store/store";
import axios from "axios";

const {
  REGISTER_API_ROUTE,
  LOGIN_API_ROUTE,
  RESET_PASSWORD_API_ROUTE,
  UPDATE_USER_API_ROUTE,
  RESET_PASSWORD_API_ROUTE_SENT,
  CHANGE_USER_PASSWORD_API_ROUTE,
  USER_API_ROUTE,
  GET_USER_API_ROUTE,
} = require("../../routes/api_routes");
const {setCurrentUser, setUsersList} = require("../reducers/users");

const dispatch = store.dispatch;

const users = {
  getUsersList() {
    return new Promise((resolve, reject) => {
      axios
        .get(USER_API_ROUTE)
        .then((response) => {
          if (response.status === 200) {
            const users = response.data;
            dispatch(setUsersList(users));
            resolve(users);
          } else {
            reject(new Error(`Unexpected status code: ${response.status}`));
          }
        })
        .catch((error) => reject(error));
    });
  },

  getUsers() {
    return new Promise((resolve, reject) => {
      axios
        .get(GET_USER_API_ROUTE)
        .then((response) => {
          if (response.status === 200) {
            const users = response.data;
            dispatch(setUsersList(users));
            resolve(users);
          } else {
            reject(new Error(`Unexpected status code: ${response.status}`));
          }
        })
        .catch((error) => reject(error));
    });
  },

  resetPassword(userId, newPassword) {
    return new Promise((resolve, reject) => {
      axios
        .put(`${CHANGE_USER_PASSWORD_API_ROUTE}${userId}/`, {
          password: newPassword,
        })
        .then((response) => {
          if (response.status === 200) {
            resolve(response.data);
          } else {
            reject(new Error(`Unexpected status code: ${response.status}`));
          }
        })
        .catch((error) => reject(error));
    });
  },

  registerUser(data) {
    return new Promise((resolve, reject) => {
      axios
        .post(REGISTER_API_ROUTE, data)
        .then((response) => {
          if (response.status === 201) {
            const user = response.data;
            resolve(user);
          } else {
            reject(new Error(`Unexpected status code: ${response.status}`));
          }
        })
        .catch((error) => reject(error));
    });
  },

  loginUser(data) {
    return new Promise((resolve, reject) => {
      axios
        .post(LOGIN_API_ROUTE, data)
        .then((response) => {
          if (response.status === 200) {
            const user = response.data;
            const userData = {
              id: user.id,
              nom_complet: user.nom_complet,
              phone: user.phone,
              email: user.email,
              token: user.token,
            };
            localStorage.setItem("currentUser", JSON.stringify(userData));

            dispatch(setCurrentUser(user));
            resolve(user);
          } else {
            reject(new Error(`Unexpected status code: ${response.status}`));
          }
        })
        .catch((err) => reject(err));
    });
  },

  findUserByPhone(phone) {
    return new Promise((resolve, reject) => {
      this.getUsers()
        .then((users) => {
          const user = users.find((u) => u.phone === phone);
          if (user) {
            resolve(user);
          } else {
            reject(new Error("User not found"));
          }
        })
        .catch((error) => reject(error));
    });
  },

  // Additional methods for password reset and update as needed
};

export default users;
