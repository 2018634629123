export function converDate(date) {
  const newdate = new Date(date);

  return `${addZero(newdate.getDate())}/${addZero(
    newdate.getMonth() + 1
  )}/${newdate.getFullYear()}`;
}

export function convertDateToString(date) {
  const newdate = new Date(date);

  const months = [
    "Janvier",
    "Fevrier",
    "Mars",
    "Avril",
    "Mai",
    "Juin",
    "Juillet",
    "Aout",
    "Septembre",
    "Octobre",
    "Novembre",
    "Decembre",
  ];

  return `Le ${addZero(newdate.getDate())} ${
    months[newdate.getMonth()]
  } ${newdate.getFullYear()}`;
}



function addZero(nombre) {
  if (nombre < 10) {
    return "0" + nombre;
  } else {
    return nombre;
  }
}
