import { createSlice } from "@reduxjs/toolkit";

const currentTabSlice = createSlice({
  name: 'currentTab',
  initialState: { currentTab: 0 },
  reducers: {
    setCurrentTab: (state, { payload }) => {
      state.currentTab = payload
    },
    removeCurrentTab: (state) => {
      state.currentTab = 0
    }
  }
})

export const { setCurrentTab, removeCurrentTab } = currentTabSlice.actions
export default currentTabSlice.reducer