import Page404 from "../components/Page404";
import LoginPage from "../pages/auth/LoginPage";
import RegisterPage from "../pages/auth/RegisterPage";
import DetailsDepense from "../pages/depence/DetailsDepense";
// import AddProduct from "../pages/products/AddProduct";
import DetailsProduits from "../pages/products/DetailsProduits";
import {Products} from "../pages/products/Products";
import {
  ACCUEIL_PATH,
  ADD_PRODUCT_PATH,
  PRODUCT_PATH,
  LOGIN_PATH,
  REGISTER_PATH,
  DETAILS_VENTE_PATH,
  DETAILS_PRODUCT_PATH,
  DETAILS_DEPENSE_PATH,
  SETTINGS_PATH,
  HISTORIQUE_PATH,
  FORGOT_PASSWORD_PATH,
  NEW_PASSWORD_PATH,
} from "../utils/navigation_paths";
import {lazy} from "react";
import AppLazy from "../components/AppLazy";
import ForgotPasswordPage from "../pages/auth/ForgotPasswordPage";
import NewPasswordPage from "../pages/auth/NewPasswordPage";

const {Navigate} = require("react-router-dom");

const AccueilPage = lazy(() => import("../pages/accueil/AccueilPage"));
const HistoriqueHome = lazy(() => import("../pages/historique/HistoriqueHome"));
const AddProduct = lazy(() => import("../pages/products/AddProduct"));
const DetailsVente = lazy(() => import("../pages/vente/DetailsVente"));
const Settings = lazy(() => import("../pages/setting"));

const navigation_routes = [
  {
    path: "/",
    element: <Navigate to={ACCUEIL_PATH} />,
  },
  {
    path: LOGIN_PATH,
    element: <LoginPage />,
  },
  {
    path: REGISTER_PATH,
    element: <RegisterPage />,
  },
  {
    path: FORGOT_PASSWORD_PATH,
    element: <ForgotPasswordPage />,
  },
  {
    path: NEW_PASSWORD_PATH,
    element: <NewPasswordPage />,
  },
  {
    path: ACCUEIL_PATH,
    element: (
      <AppLazy>
        <AccueilPage />
      </AppLazy>
    ),
  },
  {
    path: DETAILS_VENTE_PATH + ":id",
    element: (
      <AppLazy>
        <DetailsVente />
      </AppLazy>
    ),
  },
  {
    path: DETAILS_PRODUCT_PATH + ":id",
    element: (
      <AppLazy>
        <DetailsProduits />
      </AppLazy>
    ),
  },
  {
    path: DETAILS_DEPENSE_PATH + ":id",
    element: <DetailsDepense />,
  },
  {
    path: SETTINGS_PATH,
    element: (
      <AppLazy>
        <Settings />
      </AppLazy>
    ),
  },
  {
    path: PRODUCT_PATH,
    element: <Products />,
  },
  {
    path: ADD_PRODUCT_PATH,
    element: (
      <AppLazy>
        <AddProduct />
      </AppLazy>
    ),
  },
  {
    path: HISTORIQUE_PATH,
    // element: <HistoriqueHome />,
    element: (
      <AppLazy>
        <HistoriqueHome />
      </AppLazy>
    ),
  },
  {
    path: "*",
    element: <Page404 />,
  },
];

export const not_protected_navigation = [
  {
    path: LOGIN_PATH,
    element: <LoginPage />,
  },
  {
    path: REGISTER_PATH,
    element: <RegisterPage />,
  },
];

export default navigation_routes;
