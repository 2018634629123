// const { default: axios } = require("axios");
import axios from 'axios'

const { GET_PRODUITS_API_ROUTE, ADD_PRODUCT_API_ROUTE, UPDATE_PRODUIT_API_ROUTE, DELETE_PRODUIT_API_ROUTE, GET_USER_PRODUCTS_API_ROUTE } = require("../../routes/api_routes")
const { addNewProduit, updateProduitInList, archiveProduitInList, setProductsList } = require("../reducers/products")

const produits = {
    getUserProductsList(dispatch, token, userId) {
        return new Promise((resolve, reject) => {
            axios.get(GET_USER_PRODUCTS_API_ROUTE + userId + '/', { headers: { Authorization: `Bearer ${token}` } })
                .then(response => {
                    if (response.data) {
                        let produits = response.data

                        dispatch(setProductsList(produits))
                        resolve(produits)
                    } else {
                        reject(response)
                    }
                })
        })
    },
    createNewProduct(data, dispatch) {
        return new Promise((resolve, reject) => {
            const token = "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2VyX2lkIjoxNTQsInVzZXJuYW1lIjoiNzczMDQwODQzIiwiZXhwIjoxNjcxNjQzOTc0LCJwaG9uZSI6Ijc3MzA0MDg0MyJ9.WbtQIlGiH41_Wa_2DEysPjdry-09xiu8x4jP4DnFkPQ"
            axios.post('http://api-fewnu.bakeli.tech/api/v1/products/', data, { headers: { Authorization: `Bearer ${token}`, "X-CSRFToken": data.data } })
                .then(response => {
                    if (response.data.id) {
                        let newProduct = response.data
                        dispatch(addNewProduit(newProduct))
                        resolve(response)
                    } else {
                        reject(response)
                    }
                })
                .catch(err => reject(err))
        })
    },
    updateProduit(id, data, token, dispatch) {
        return new Promise((resolve, reject) => {
            axios.put(UPDATE_PRODUIT_API_ROUTE + id + '/', data, { headers: { Authorization: `Bearer ${token}` } })
                .then(response => {
                    if (response.data.id) {
                        let newProduct = response.data
                        dispatch(updateProduitInList(newProduct))
                        resolve(newProduct)
                    } else {
                        reject(response)
                    }
                })
                .catch(err => reject(err))
        })
    },
    archiveProduct(produitId, token, dispatch, userId) {
        return new Promise((resolve, reject) => {
            axios.delete(DELETE_PRODUIT_API_ROUTE + produitId + '/', { headers: { Authorization: `Bearer ${token}` } })
                .then(response => {
                    if (response.status === 204) {
                        // dispatch(archiveProduitInList(prod))
                        
                        this.getUserProductsList(dispatch, token, userId)
                        resolve("Produit supprimer")
                    } else {
                        reject(response)
                    }
                })
                .catch(err => reject(err))
        })
    }
}

export default produits