import React from "react";
import { BsArrowLeft } from "react-icons/bs";
import { Link } from "react-router-dom";
import { ACCUEIL_PATH } from "../utils/navigation_paths";

function DetailsLayout(props) {
  return (
    <div>
      <div
        style={{
          height: 50,
          width: "100%",
          //   backgroundColor: "red",
          alignItems: "center",
          display: "flex",
          padding: "0 15px",
          borderBottom: "1px solid rgba(0,0,0,0.1)",
        }}
      >
        <Link to={ACCUEIL_PATH}>
          <BsArrowLeft size={30} />
        </Link>
        <h3
          style={{
            marginLeft: 10,
            fontSize: 20,
            fontWeight: 500,
            marginTop: 10,
          }}
        >
          {props.title} N'{props.id}
        </h3>
      </div>
      <div style={{ padding: "20px 10px" }}>{props.children}</div>
    </div>
  );
}

export default DetailsLayout;
