import { createSlice } from "@reduxjs/toolkit/"
import { productsGenerator } from "../../utils/data_generator"

let initialState = {
    currentProduct: {},
    listProducts: [],
    listProductsAll: [],
    selectedProductId: null,
    openDelete: false,
    selectedProduct: {}
}
const productsSlice = createSlice({
    name: 'products',
    initialState,
    reducers: {
        setCurrentProduct: (state, { payload }) => {
            state.currentProduct = payload
        },
        setProductsList: (state, { payload }) => {
            state.listProducts = payload
            state.listProductsAll = payload
        },
        resetCurrentProduct: (state, { payload }) => {
            state.currentProduct = {}
        },
        filterProductsBySearch: (state, { payload }) => {
            state.listProducts = state.listProductsAll.filter(item => {
                let fields = payload.fields.map((field) => item[field])

                let data = fields.join(' ').toLowerCase()
                return data.includes(payload.value.toLowerCase())
            })
        },
        setProductIdToDelete: (state, { payload }) => {
            state.selectedProductId = payload
        },
        setOpenDelete: (state, { payload }) => {
            state.openDelete = payload
        },
        setSelectedProduct: (state, { payload }) => {
            state.selectedProduct = payload
        }
    }
})

export const { setCurrentProduct, setProductsList, resetCurrentProduct, filterProductsBySearch, setProductIdToDelete, setOpenDelete, setSelectedProduct } = productsSlice.actions
export default productsSlice.reducer