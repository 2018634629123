/** +++++++++++++++++++++++++++++++++++++++++++++++++++++++
 *  !API PROD
 +++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
export const DEFAULT_API = "https://api-fewnu.bakeli.tech/api/v1/";
export const DEFAULT_API_IMAGE = "https://api-fewnu.bakeli.tech/api";
export const DEFAULT_API_VIDEO = "https://api-fewnu.bakeli.tech/api";
/** +++++++++++++++++++++++++++++++++++++++++++++++++++++++
 *  !API RECETTE
 +++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
//  export const DEFAULT_API = 'http://167.99.253.190:9000/api/v1/'
// export const DEFAULT_API_IMAGE = 'http://167.99.253.190:9000/api'
/** +++++++++++++++++++++++++++++++++++++++++++++++++++++++
 *  !API LOCAL
 +++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
// export const DEFAULT_API = "http://127.0.0.1:8000/api/v1/";
// export const DEFAULT_API_IMAGE = "http://127.0.0.1:8000/api";
// export const DEFAULT_API_VIDEO = "http://127.0.0.1:8000/api";

export const REGISTER_API_ROUTE = DEFAULT_API + "users/";
export const GET_USER_API_ROUTE = DEFAULT_API + "users/";
export const LOGIN_API_ROUTE = DEFAULT_API + "login/";
export const LOGOUT_API_ROUTE = DEFAULT_API + "logout/";
export const UPDATE_USER_API_ROUTE = DEFAULT_API + "user/"; // + {id}/
export const CHANGE_USER_PASSWORD_API_ROUTE = DEFAULT_API + "users/password/"; // + {id}/

/** +++++++++++++++++++++++++++++++++++++++++++++++++++++++
 *  VENTES PATHS
 +++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
export const GET_USER_VENTES_API_ROUTE = DEFAULT_API + "ventes/user/"; // + {userId}
export const ADD_VENTES_API_ROUTE = DEFAULT_API + "ventes/";
export const DELETE_VENTE_API_ROUTE = DEFAULT_API + "ventes/"; // + {id}

/** +++++++++++++++++++++++++++++++++++++++++++++++++++++++
 *  PRODUITS PATHS
 +++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
export const GET_USER_PRODUCTS_API_ROUTE = DEFAULT_API + "products/user/"; // + {id}
export const DELETE_PRODUIT_API_ROUTE = DEFAULT_API + "products/"; // + {id}

/** +++++++++++++++++++++++++++++++++++++++++++++++++++++++
 *  SOLDE USER PATH
 +++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
export const GET_USER_SOLDE_API_ROUTE = DEFAULT_API + "soldes/user/"; // + {id}
export const RESET_SOLDE_API_ROUTE = DEFAULT_API + "soldes/init/"; // + {id}

/** +++++++++++++++++++++++++++++++++++++++++++++++++++++++
 *  VENTES PATHS
 +++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
export const ADD_PRODUCT_API_ROUTE = DEFAULT_API + "products/";
export const EDIT_PRODUCT_API_ROUTE = DEFAULT_API + "products/"; // + {productId}

/** +++++++++++++++++++++++++++++++++++++++++++++++++++++++
 *  DEPENSES PATHS
 +++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
export const DELETE_DEPENSE_API_ROUTE = DEFAULT_API + "depenses/"; // + {id}
