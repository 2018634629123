import { createSlice } from "@reduxjs/toolkit";

const soldeSlice = createSlice({
  name: 'solde',
  initialState: {solde: null},
  reducers:{
    setSolde: (state, {payload}) => {
      state.solde = payload
    },
    initializeSolde:(state) => {
      state.solde = null
    }
  }
})

export const {setSolde, initializeSolde} = soldeSlice.actions
export default soldeSlice.reducer